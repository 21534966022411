import React, {useEffect, useState} from 'react';
import {
    Datagrid,
    DateField,
    fetchEnd,
    fetchError,
    fetchStart,
    Filter,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectField,
    SelectInput,
    TextField,
    TextInput,
    useDataProvider,
    useTranslate
} from 'react-admin';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles, Paper} from '@material-ui/core';
import {DateInputPicker} from '../components/input';
import {BulkCancelBookingsButton, TooltipTextField} from "../components";
import ConfirmedStatus from '@material-ui/icons/Done';
import CancelledStatus from '@material-ui/icons/Clear';
import {LANGUAGE} from "../authClient";
import {goServiceTrips} from "../components/CommonUtils";

const EMPTY_TEXT = 'allSelectInput.emptyText';

const useStyles = makeStyles({
    headerCell: {
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    paper: {
        maxHeight: 'calc(60vh - 100px)',
        overflowY: 'auto',
        marginTop: 4
    },
    firstFilterBox: {
        paddingBottom: 0
    },
    secondFilterBox: {
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 0
    },
    thirdFilterBox: {
        marginTop: 0,
        paddingTop: 0
    }
});

const statusChoice = [
    {id: 'CONFIRMED', name: 'statusChoice.confirmed'},
    {id: 'CANCELLED', name: 'statusChoice.cancelled'}
];

const statusIcon = [
    {id: 'CONFIRMED', name: <ConfirmedStatus/>},
    {id: 'CANCELLED', name: <CancelledStatus/>}
];

const passengerChoices = [
    {id: 'ADULT', name: 'passengerChoice.adult'},
    {id: 'CHILD', name: 'passengerChoice.child'},
    {
        id: 'INFANT',
        name: 'passengerChoice.infant'
    },
    {
        id: 'PINT',
        name: 'passengerChoice.pint'
    }
];

const Filters = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const viewVersion = useSelector(state => state.admin.ui.viewVersion);
    const [agencies, setAgencies] = useState([]);

    useEffect(() => {
        dispatch(fetchStart());
        dataProvider.customGet('agencies', {})
            .then(response => {
                setAgencies(response.data)
            })
            .catch(e => {
                if (e.status === 401 || e.status === 403) {
                    dispatch(fetchError());
                }
            })
            .finally(() => {
                dispatch(fetchEnd())
            });
    }, [dataProvider, dispatch, viewVersion]);

    return (
        <div>
            <Filter {...props} className={classes.firstFilterBox} variant={"outlined"}>
                <TextInput source={'id'} alwaysOn style={{width: 175}} resettable/>
                <TextInput source={'extBookingId'} alwaysOn resettable style={{width: 175}}/>
                <SelectInput source={'status'} alwaysOn choices={statusChoice} resettable clearAlwaysVisible={false}
                             style={{width: 175}} emptyText={EMPTY_TEXT}
                             options={{
                                 SelectProps: {displayEmpty: true},
                                 InputLabelProps: {shrink: true}
                             }}/>
                <SelectInput source={'agencyId'} alwaysOn choices={agencies} resettable clearAlwaysVisible={false}
                             style={{width: 175}} emptyText={EMPTY_TEXT}
                             options={{
                                 SelectProps: {displayEmpty: true},
                                 InputLabelProps: {shrink: true}
                             }}/>
                <DateInputPicker source={'bookingDate'} clearable alwaysOn style={{width: 175}}
                                 invalidDateRequest={false}/>
            </Filter>
            <Filter {...props} className={classes.secondFilterBox} variant={"outlined"}>
                <DateInputPicker source={'fromDate'} clearable alwaysOn style={{width: 175}}
                                 invalidDateRequest={false}/>
                <DateInputPicker source={'toDate'} clearable alwaysOn style={{width: 175}} invalidDateRequest={false}/>
            </Filter>
            <Filter {...props} className={classes.thirdFilterBox} variant={"outlined"}>
                <ReferenceInput
                    source={'serviceId'}
                    reference={'services'}
                    disabled={!!(props.filterValues && props.filterValues.productId)}
                    sort={{field: 'id', order: 'ASC'}}
                    alwaysOn
                    resettable
                    clearAlwaysVisible={false}
                    style={{minWidth: 175}}
                >
                    <SelectInput
                        optionText={(record) => (`${record.id} - ${record.shortDescription}`)}
                        translateChoice={false}
                        emptyText={EMPTY_TEXT}
                        options={{
                            SelectProps: {displayEmpty: true},
                            InputLabelProps: {shrink: true}
                        }}
                    />
                </ReferenceInput>
                <ReferenceInput
                    source={'productId'}
                    reference={'products'}
                    disabled={!!(props.filterValues && props.filterValues.serviceId)}
                    sort={{field: 'id', order: 'ASC'}}
                    alwaysOn
                    resettable
                    clearAlwaysVisible={false}
                    style={{minWidth: 175}}
                >
                    <SelectInput
                        optionText={(record) => (`${record.id} - ${record.shortDescription}`)}
                        translateChoice={false}
                        emptyText={EMPTY_TEXT}
                        options={{
                            SelectProps: {displayEmpty: true},
                            InputLabelProps: {shrink: true}
                        }}
                    />
                </ReferenceInput>
            </Filter>
        </div>
    );
};

const PassengersDetails = ({record}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const passengers = record['passengers'];
    if (passengers && passengers.length > 0) {
        const data = {};
        const ids = [];
        passengers.forEach(passenger => {
            const id = passenger.ticketId;
            data[id] = passenger;
            ids.push(id)
        });
        return <Paper className={classes.paper}>
            <Datagrid
                classes={{headerCell: classes.headerCell}}
                data={data}
                ids={ids}
                currentSort={{
                    field: 'ticketId',
                    order: 'ASC'
                }}
            >
                <TextField source={'firstName'} label={translate('bookings.passengers.firstName')} sortable={false}/>
                <TextField source={'lastName'} label={translate('bookings.passengers.lastName')} sortable={false}/>
                <TextField source={'email'} label={translate('bookings.passengers.email')} sortable={false}/>
                <TextField source={'phoneNumber'} label={translate('bookings.passengers.phone')} sortable={false}/>
                <SelectField
                    label={translate('bookings.passengers.passengerCategory')}
                    source={'passengerCategoryId'}
                    choices={passengerChoices}
                    sortable={false}
                />
                <TextField source={'outboundSeatId'} label={translate('bookings.passengers.outboundSeat')}
                           sortable={false}/>
                <TextField source={'inboundSeatId'} label={translate('bookings.passengers.inboundSeat')}
                           sortable={false}/>
                <TextField source={'itemCode'} label={translate('bookings.passengers.itemCode')} sortable={false}/>
                <NumberField
                    label={translate('bookings.passengers.price')}
                    source={'price'}
                    options={{style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2}}
                    sortable={false}
                />
            </Datagrid>
        </Paper>
    } else {
        return <div/>
    }
};

export const UiBookings = (props) => {
    const translate = useTranslate();
    const locale = localStorage.getItem(LANGUAGE);
    const noSecondsDateTimeOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };

    return <List
        title={translate('resources.bookings.name')}
        filters={<Filters/>}
        filterDefaultValues={{
            'fromDate': null,
            'toDate': null,
            'status': 'CONFIRMED'
        }}
        actions={null}
        bulkActionButtons={<BulkCancelBookingsButton/>}
        sort={{
            field: 'bookingDate',
            order: 'DESC'
        }}
        {...props}
    >
        <Datagrid expand={<PassengersDetails/>}
                  hover={false}>
            <SelectField source={'status'} choices={statusIcon} translateChoice={false} sortable={false}/>
            <TextField source={'id'}/>
            <DateField showTime source={'bookingDate'} locales={locale} options={noSecondsDateTimeOptions}/>
            <TextField source={'seller'}/>
            <DateField showTime source={'outboundDepartureDateTime'} locales={locale}/>
            <DateField showTime source={'outboundArrivalDateTime'} locales={locale}/>
            <DateField showTime source={'inboundDepartureDateTime'} locales={locale}/>
            <DateField showTime source={'inboundArrivalDateTime'} locales={locale}/>
            <TooltipTextField source={'originStopId'} tooltipSource={'origin'}
                              sortable={false}/>
            <TooltipTextField source={'destinationStopId'} tooltipSource={'destination'} sortable={false}/>
            <ReferenceField
                allowEmpty={true}
                source={'serviceId'}
                reference={'services'}
                link={false}
                sortable={false}
            >
                <TooltipTextField source={'id'} tooltipSource={'shortDescription'} link={goServiceTrips}/>
            </ReferenceField>
            <ReferenceField
                allowEmpty={true}
                source={'productId'}
                reference={'products'}
                link={false}
                sortable={false}
            >
                <TooltipTextField source={'id'} tooltipSource={'shortDescription'}/>
            </ReferenceField>
            <TextField source={'extBookingId'}/>
            <NumberField
                source={'totalPrice'}
                locales={locale}
                options={{
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }}
            />
        </Datagrid>
    </List>
};
